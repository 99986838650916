import { createRouter, createWebHistory } from "vue-router";
import HomePage from "./src/pages/HomePage.vue";
import Article from "./src/pages/Article.vue";
import OctaGT from "./src/pages/OctaGT.vue";
import { articles } from "./src/articles-id.js";
import AboutNew from "./src/pages/AboutNew.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: {
      title: "IMOTO: Inovasi EV untuk Transportasi Bebas Polusi",
      metaTags: [
        {
          name: "description",
          content:
            "IMOTO produsen EV roda dua Indonesia, hadir dengan ekosistem EV inovatif untuk pasar ASEAN & India, siap mengurangi emisi karbon dengan teknologi unggulan.",
        },
        {
          name: "keywords",
          content:
            "IMOTO, motor listrik, kendaraan listrik, teknologi masa depan, EV Indonesia",
        },
        {
          property: "og:description",
          content:
            "IMOTO Indonesia diciptakan dengan teknologi canggih dan material inovatif yang tangguh, menjadi simbol revolusi kendaraan listrik masa depan dari Indonesia",
        },
      ],
    },
  },
  {
    path: "/about",
    name: "About",
    component: AboutNew,
    meta: {
      title: "Tentang IMOTO - Inovasi Kendaraan Listrik",
      metaTags: [
        {
          name: "description",
          content:
            "IMOTO hadirkan inovasi motor listrik dengan ekosistem EV canggih, Siap memimpin dengan teknologi terdepan dan R&D yang dikembangkan di Indonesia.",
        },
        {
          name: "keywords",
          content:
            "tentang IMOTO, motor listrik, inovasi EV, mobilitas listrik Indonesia, teknologi ramah lingkungan",
        },
        {
          property: "og:description",
          content:
            "IMOTO hadirkan inovasi motor listrik dengan ekosistem EV canggih, Siap memimpin dengan teknologi terdepan dan R&D yang dikembangkan di Indonesia.",
        },
      ],
    },
  },
  {
    path: "/product/OctaGT",
    name: "Octa GT",
    component: OctaGT,
    meta: {
      title: "IMOTO Octa GT : Gaya Maksimal, Nyaman optimal",
      metaTags: [
        {
          name: "description",
          content:
            "IMOTO Octa GT hadir dengan desain futuristik dengan performa tinggi. Solusi modern, efisien, dan ramah lingkungan untuk masa bebas polusi.",
        },
        {
          name: "keywords",
          content:
            "IMOTO Octa GT, motor listrik futuristik, performa tinggi, kendaraan ramah lingkungan, desain modern",
        },
        {
          property: "og:description",
          content:
            "IMOTO Octa GT hadir dengan desain futuristik dengan performa tinggi. Solusi modern, efisien, dan ramah lingkungan untuk masa bebas polusi.",
        },
      ],
    },
  },
  {
    path: "/article/:id/:slug?",
    name: "Article",
    component: Article,
    meta: {
      title: "Article",
      metaTags: [
        {
          name: "description",
          content:
            "Artikel terbaru dari IMOTO tentang motor listrik dan teknologi EV.",
        },
        {
          name: "keywords",
          content:
            "artikel IMOTO, berita motor listrik, teknologi EV, update IMOTO",
        },
        {
          property: "og:description",
          content:
            "Artikel terbaru dari IMOTO tentang motor listrik dan teknologi EV.",
        },
      ],
    },
  },
  {
    path: "/article",
    redirect: () => {
      console.log("Redirecting to default article...");
      console.log("Articles available:", articles); // Debugging log

      const defaultArticle = articles.find((article) => article.id === "10");

      if (!defaultArticle) {
        console.error("Default article not found!");
        return { name: "Home" };
      }
      return {
        name: "Article",
        params: { id: defaultArticle.id, slug: defaultArticle.slug },
      };
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let metaTitle = "Default Title";
  let metaTags = [
    {
      name: "description",
      content: "Default description",
    },
    {
      property: "og:description",
      content: "Default description for Open Graph.",
    },
  ];

  if (to.name === "Article" && to.params.id) {
    const article = articles.find((a) => a.id == to.params.id);
    if (article) {
      console.log("Found article:", article);
      metaTitle = article.title; // Ambil judul dari artikel
      metaTags = [
        {
          name: "description",
          content: article.description || article.content.substring(0, 150),
        },
        {
          property: "og:description",
          content: article.description || article.content.substring(0, 150),
        },
      ];
    } else {
      console.error("Article not found for ID:", to.params.id);
    }
  } else if (to.meta && to.meta.title) {
    metaTitle = to.meta.title;
    metaTags = to.meta.metaTags || metaTags;
  }

  document.title = metaTitle;

  // Remove old meta tags
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Add new meta tags
  metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute("data-vue-router-controlled", "");
      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
