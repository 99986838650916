export default {
  // LANDING PAGE
  download: `<p class="text-white text-lg md:text-4xl">
        Kendali dalam satu genggaman.
        <b>Temukan, Pantau, dan Atur</b> perjalanan mu dengan
        <b>IMOTO.ev</b>
      </p>`,
  available: "Tersedia di:",
  carbonReduced:
    "IMOTO Indonesia hadir dengan visi besar yaitu menciptakan masa depan tanpa emisi karbon. Dengan kendaraan listrik berperforma tinggi, kami tidak hanya mengubah cara berkendara, tetapi juga mendorong Indonesia menuju era dekarbonisasi. Setiap pengurangan 10 kg CO₂ yang Anda lakukan setara dengan menanam satu pohon selama sebulan, Ini merupakan langkah kecil untuk lompatan besar bagi bumi kita! Bersama IMOTO, mari jadikan keberlanjutan sebagai gaya hidup baru dalam transportasi.",
  kmsCovered:
    "Tahukah Anda? Pada tahun 2024, Indonesia memiliki sekitar 137 juta kendaraan bermotor di jalan raya. IMOTO hadir sebagai solusi untuk mengurangi jejak karbon dengan mobilitas ramah lingkungan. Setiap kilometer yang Anda tempuh bersama kami adalah napas baru untuk udara yang lebih bersih dan planet yang lebih sehat. bersama IMOTO Inronesia.",

  //   OCTA PAGE

  unleash: `<p class="text-white text-lg md:text-3xl">
              Bebaskan <b>Semangat Muda</b> anda bersama
            </p>`,
  yourStyle: `<h2 class="text-white text-lg md:text-3xl leading-10 mt-4">
             Miliki Gaya Anda, Semarkan Hidup Anda. <br />
              <b>Pesan Milik Anda</b>
            </h2>`,

  // OCTA spesification
  spesification: "Spesifikasi",
  battery: "Baterai",
  tyres: "Ban (D/B)",
  speed: "Kecepatan Maksimal",
  motor: "Kapasitas Mesin",
  transmission: "Model Transmisi",
  hub: "Hub Drive",
  cooling: "Metode Pendinginan",

  //   OCTA Gallery
  title1: "Suspensi Depan",
  desc1: "Spring Rocker Arm Front Shock",
  title2: "Suspensi Belakang",
  desc2: "Hydraulic Spring Shock Absorber",
  title3: "Dudukan Kotak",
  desc3: "Rak Praktis, Kemudahan Maksimal",
  title4: "Tombol",
  desc4: "Desain yang nyaman ditekan",
  title5: "Jarak pencahayaan hingga 65M",
  desc5: "Lighting distance 65M",
  title6: "Modul Baterai",
  desc6: "Waktu pengisian daya 4 jam",

  // Ride in style
  rideIn: "Sensasi berkendara penuh gaya setiap hari!",
  descRidein:
    "OCTA GT menawarkan ketahanan, kekuatan tenaga dan kenyamanan untuk berkendara sehari-hari yang menyenangkan",

  // Be the spark
  spark: "BANGKITKAN SEMANGAT",
  octageneration: "#OCTAGENERATION",
  embrace:
    "Hidupkan hari-hari penuh warna dan keseruan Hidupkan hari-hari penuh warna dan keseruan, Pilihan Sempurna untuk gaya hidup urban yang menyatu dengan prinsip keberlanjutan.",
  price: "Harga",
  price1: "1 Baterai RP. 26.000.000",
  price2: "2 Baterai RP. 36.000.000",
  Define: "Temukan warnamu",

  // Connect with App
  connect: "Terhubung Dengan Aplikasi",
  descConnect:
    "Eksplorasi potensi sepeda Anda sepenuhnya. Naikkan level berkendara Anda dengan fitur modern dan kontrol optimal, IMOTO.EV, partner setia di setiap perjalanan Anda.",

  // Test Ride
  testride: "Buat Janji Test Ride",
  descTestride:
    "Kirimkan data diri untuk mengetahui keuntungan pemesanan kami dengan penawaran eksklusif.",
  noteTestride: "Harap membawa SIM Anda untuk melakukan test drive.",
  tncTitle: "*Syarat dan Ketentuan",
  tncTestride:
    "Saya setuju bahwa Anda dan IMOTO Indonesia dan/atau penyedia layanannya dapat menghubungi saya dan/atau mengirimkan informasi pemasaran dan/atau informasi acara dan promosi kepada saya mengenai produk dan layanan Anda atau mereka melalui SMS, MMS, faks, surat atau email.",
  bookNowButton: "Pesan Sekarang",

  // ABOUT PAGE
  ourStory: "Perjalanan Kami",
  whoAreWe: "Siapa kami?",
  storyDesc: `merupakan sebuah perusahaan yang dikelola oleh para profesional dan penggemar otomotif global yang memiliki kecintaan mendalam terhadap otomotif dan teknologi. Kami meyakini bahwa masa depan industri otomotif Indonesia terletak pada kendaraan listrik (EV). Oleh karena itu, Imoto berkomitmen untuk terus berinovasi dalam mengembangkan produk dan layanan yang mendukung terciptanya mobilitas bebas karbon dengan biaya adopsi yang terjangkau.
<br> <br> belakang yang kuat, Imoto tengah mengembangkan sepeda motor listrik dengan semboyan Dari Indonesia, Oleh indonesia dan Untuk Indonesia. ketika pengendara dapat merasakan adrenalin yang mengalir begitu mereka duduk di atas motornya. Semua sepeda motor kami akan mengusung teknologi "FuTech" dari IMOTO, Menawarkan performa tinggi tanpa kompromi, serta diharapkan dapat mengubah cara `,
  ourThing: "Fokus Kami",
  whyWeExist: " Mengapa kita hadir",
  thingDesc:
    "Imoto bertekad kuat untuk mengubah kehidupan dengan menghadirkan mobilitas listrik berperforma tinggi yang bebas emisi. Kami berkomitmen untuk menciptakan teknologi yang mempercepat dekarbonisasi dan menghadirkan masa depan mobilitas yang lebih bersih dan berkelanjutan.",
  ourMission: "Misi Kami",
  weDoing: "Apa yang Kami Bangun?",
  doingDesc:
    "IMOTO didirikan tahun 2021 sebagai perusahaan OEM otomotif yang sepenuhnya berkembang dan berfokus pada mobilitas listrik, dan pusat R&D di Indonesia dan India. Kami memiliki keyakinan kuat bahwa pada tahun 2035, Imoto akan menjadi perusahaan dengan emisi karbon nol (Zero Carbon), dan produk-produk kami akan mewujudkan visi serta komitmen yang kami tetapkan sejak awal. Didukung oleh tim dengan latar belakang balap yang solid, kami bekerja dengan semangat untuk menghadirkan teknologi EV terbaik kepada konsumen, dengan harga yang sangat terjangkau. Dengan pusat R&D di Indonesia dan India, Imoto tengah mengembangkan:",
  doingList1: "Teknologi IMOTO controller",
  doingList2: "Desain Rangka yang dibangun dari Awal khusus untuk EV.",
  doingList3: "Material Rangka dan Body yang Inovatif dan Tangguh.",
  ourTech: "Teknologi Kami",
  techProduct: "Produk Teknologi kami",
  techDesc:
    "Sejak awal didirikan, IMOTO berhasil meluncurkan produk-produk yang inovatif, baik perangkat keras maupun perangkat lunak yang dikembangkan secara mandiri. Berikut adalah beberapa produk unggulan kami.",
  techList1:
    "Platform layanan inovatif untuk mobilitas elektrik pintar (MaaS) yang menghadirkan solusi berlangganan sepeda motor listrik. Dengan kemudahan dan keamanan penggunaan yang tinggi, Imotoshare menawarkan performa unggul, daya tahan baterai yang lebih lama, serta kenyamanan berkendara yang tak tertandingi dibandingkan sepeda motor konvensional. Sebagai pilihan tepat untuk memenuhi kebutuhan transportasi Anda, Imotoshare memberikan pengalaman berkendara yang efisien, ramah lingkungan, dan modern, menjadikannya solusi terbaik untuk mobilitas masa depan",
  techList2:
    "Imoto 1Cloud adalah ekosistem manajemen armada kendaraan listrik berbasis cloud yang sepenuhnya dikembangkan secara internal, dirancang untuk mengubah cara Anda mengelola kendaraan listrik dengan lebih cerdas dan efisien. Dilengkapi dengan aplikasi Imoto yang canggih, Imoto 1Cloud memberikan wawasan big data yang mendalam, memperkaya pengalaman berkendara Anda dengan interaktivitas yang lebih tinggi dan informasi secara real-time. Imoto 1Cloud adalah kunci untuk membuka potensi penuh armada kendaraan listrik Anda, menawarkan solusi yang lebih cerdas dan efisien.",
  techList3: `Imoto telah menciptakan inovasi luar biasa dengan baterai "Rapid" Fast-Charging, yang mampu mengisi daya dari 20% hingga 80% hanya dalam 30 menit. Ini adalah teknologi pertama di Indonesia, dikembangkan secara in-house oleh tim muda yang penuh semangat dalam waktu 2 tahun kerja keras dan dedikasi. Inovasi ini akan mengubah pasar kendaraan listrik (EV) di Indonesia dan mempercepat penerimaan EV di tanah air. Kini, pengendara dapat merasakan teknologi baterai canggih yang sepenuhnya dikembangkan di Indonesia. Jadi, isi daya lebih cepat, nikmati kecepatan lebih tinggi, dan berkendara lebih lama bersama kami!`,
};
